// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateCityRequest from "./requests/UpdateCityRequest.res.js";
import * as FetchCitiesRequest from "./requests/FetchCitiesRequest.res.js";
import * as FixCityCountsRequest from "./requests/FixCityCountsRequest.res.js";
import * as UpdateCityActiveRequest from "./requests/UpdateCityActiveRequest.res.js";
import * as FetchCitiesDashboardRequest from "./requests/FetchCitiesDashboardRequest.res.js";
import * as UpdateCityActiveInBatchRequest from "./requests/UpdateCityActiveInBatchRequest.res.js";

var fetchCities = FetchCitiesRequest.exec;

var fetchCitiesDashboard = FetchCitiesDashboardRequest.exec;

var updateActiveInBatch = UpdateCityActiveInBatchRequest.exec;

var updateActive = UpdateCityActiveRequest.exec;

var fixCounts = FixCityCountsRequest.exec;

var update = UpdateCityRequest.exec;

export {
  fetchCities ,
  fetchCitiesDashboard ,
  updateActiveInBatch ,
  updateActive ,
  fixCounts ,
  update ,
}
/* UpdateCityRequest Not a pure module */
