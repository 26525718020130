// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Api_City from "../../../../api/cities/Api_City.res.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Breadcrumb from "../../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../styleguide/icons/IconSorting.res.js";
import * as Routes_City from "../../../../routes/common/Routes_City.res.js";
import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as Routes_State from "../../../../routes/common/Routes_State.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Country from "../../../../routes/common/Routes_Country.res.js";
import * as Routes_Location from "../../../../routes/common/Routes_Location.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocalityIndexScss from "../../common/styles/LocalityIndex.scss";

var css = LocalityIndexScss;

function CitiesIndex$default(props) {
  var index = props.index;
  var initialState = React.useMemo((function () {
          return {
                  status: {
                    TAG: "Ready",
                    _0: index.cities
                  },
                  currentPage: index.currentPage,
                  totalPages: index.totalPages,
                  sortBy: {
                    NAME: "TotalLocations",
                    VAL: "Desc"
                  },
                  search: "",
                  totalCities: index.totalCities,
                  totalStates: index.totalStates,
                  totalCountries: index.totalCountries,
                  totalLocations: index.totalLocations
                };
        }), []);
  var container = React.useRef(null);
  var searchCountries = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "FetchCities") {
              return {
                      TAG: "SideEffects",
                      _0: (function (param) {
                          var dispatch = param.dispatch;
                          var state = param.state;
                          var match = state.search;
                          var tmp = match === "" ? undefined : state.search;
                          $$Promise.wait(Api_City.fetchCities(state.currentPage, state.sortBy, tmp), (function (x) {
                                  if (x.TAG === "Ok") {
                                    return dispatch({
                                                TAG: "SucceedCitiesFetch",
                                                _0: x._0
                                              });
                                  }
                                  SentryLogger.error1({
                                        rootModule: "CitiesIndex",
                                        subModulePath: /* [] */0,
                                        value: "default",
                                        fullPath: "CitiesIndex.default"
                                      }, "FetchCities::Error", [
                                        "Error",
                                        x._0
                                      ]);
                                  dispatch("FailStatesFetch");
                                }));
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        status: "FailedCitiesFetch",
                        currentPage: state.currentPage,
                        totalPages: state.totalPages,
                        sortBy: state.sortBy,
                        search: state.search,
                        totalCities: state.totalCities,
                        totalStates: state.totalStates,
                        totalCountries: state.totalCountries,
                        totalLocations: state.totalLocations
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "SucceedCitiesFetch" :
                var res = action._0;
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: {
                            TAG: "Ready",
                            _0: res.cities
                          },
                          currentPage: res.currentPage,
                          totalPages: res.totalPages,
                          sortBy: state.sortBy,
                          search: state.search,
                          totalCities: res.totalCities,
                          totalStates: res.totalStates,
                          totalCountries: res.totalCountries,
                          totalLocations: res.totalLocations
                        },
                        _1: (function (param) {
                            Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                    return prim.scrollTop;
                                  }));
                          })
                      };
            case "UpdatePage" :
                var nextPage = action._0;
                var match = state.status;
                if (typeof match !== "object" && match === "FetchingCities") {
                  return "NoUpdate";
                }
                if (state.currentPage !== nextPage) {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingCities",
                            currentPage: nextPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            totalCities: state.totalCities,
                            totalStates: state.totalStates,
                            totalCountries: state.totalCountries,
                            totalLocations: state.totalLocations
                          },
                          _1: (function (param) {
                              param.dispatch("FetchCities");
                            })
                        };
                } else {
                  return "NoUpdate";
                }
            case "SortBy" :
                var sort = action._0;
                var match$1 = state.status;
                if (typeof match$1 !== "object" && match$1 === "FetchingCities") {
                  return "NoUpdate";
                }
                var tmp;
                if (sort === "State") {
                  var match$2 = state.sortBy;
                  tmp = typeof match$2 === "object" && match$2.NAME === "State" && match$2.VAL !== "Desc" ? ({
                        NAME: "State",
                        VAL: "Desc"
                      }) : ({
                        NAME: "State",
                        VAL: "Asc"
                      });
                } else if (sort === "Name") {
                  var match$3 = state.sortBy;
                  tmp = typeof match$3 === "object" && match$3.NAME === "Name" && match$3.VAL !== "Desc" ? ({
                        NAME: "Name",
                        VAL: "Desc"
                      }) : ({
                        NAME: "Name",
                        VAL: "Asc"
                      });
                } else if (sort === "TotalLocations") {
                  var match$4 = state.sortBy;
                  tmp = typeof match$4 === "object" && match$4.NAME === "TotalLocations" && match$4.VAL !== "Desc" ? ({
                        NAME: "TotalLocations",
                        VAL: "Desc"
                      }) : ({
                        NAME: "TotalLocations",
                        VAL: "Asc"
                      });
                } else {
                  var match$5 = state.sortBy;
                  tmp = typeof match$5 === "object" && match$5.NAME === "Country" && match$5.VAL !== "Desc" ? ({
                        NAME: "Country",
                        VAL: "Desc"
                      }) : ({
                        NAME: "Country",
                        VAL: "Asc"
                      });
                }
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: "FetchingCities",
                          currentPage: state.currentPage,
                          totalPages: state.totalPages,
                          sortBy: tmp,
                          search: state.search,
                          totalCities: state.totalCities,
                          totalStates: state.totalStates,
                          totalCountries: state.totalCountries,
                          totalLocations: state.totalLocations
                        },
                        _1: (function (param) {
                            param.dispatch("FetchCities");
                          })
                      };
            case "UpdateSearchInput" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: state.status,
                          currentPage: state.currentPage,
                          totalPages: state.totalPages,
                          sortBy: state.sortBy,
                          search: action._0,
                          totalCities: state.totalCities,
                          totalStates: state.totalStates,
                          totalCountries: state.totalCountries,
                          totalLocations: state.totalLocations
                        },
                        _1: searchCountries
                      };
            case "PerformSearch" :
                var match$6 = state.status;
                if (typeof match$6 !== "object" && match$6 === "FetchingCities") {
                  return "NoUpdate";
                }
                if (action._0 !== state.search) {
                  return "NoUpdate";
                } else {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingCities",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            totalCities: state.totalCities,
                            totalStates: state.totalStates,
                            totalCountries: state.totalCountries,
                            totalLocations: state.totalLocations
                          },
                          _1: (function (param) {
                              param.dispatch("FetchCities");
                            })
                        };
                }
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var countries = state.totalCountries === 1 ? "country" : "countries";
  var dataCenters = state.totalLocations === 1 ? "Data Center" : "Data Centers";
  var states = state.totalStates === 1 ? "state" : "states";
  var cities = state.totalCities === 1 ? "city" : "cities";
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var cities$1 = state.status;
  var tmp;
  if (typeof cities$1 !== "object") {
    tmp = cities$1 === "FetchingCities" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: css.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var cities$2 = cities$1._0;
    tmp = cities$2.length !== 0 ? Belt_Array.mapWithIndex(cities$2, (function (index, city) {
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: city.name,
                                        className: css.tableLink,
                                        href: Routes_City.show(city.slug, city.stateSlug, city.countrySlug)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: String(city.totalLocations),
                                        className: css.tableLink,
                                        href: Routes_City.show(city.slug, city.stateSlug, city.countrySlug)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  displayOnMobile: false,
                                  children: JsxRuntime.jsx("a", {
                                        children: city.stateName,
                                        className: css.tableLink,
                                        href: Routes_State.show(city.stateSlug, city.countrySlug)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: city.countryName,
                                        className: css.tableLink,
                                        href: Routes_Country.show(city.countrySlug)
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No cities were found."
                })
          });
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Container.make, {
                    className: css.layout,
                    setRef: Caml_option.some(container),
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Breadcrumb.make, {
                                          breadcrumbLinks: [
                                            {
                                              linkName: "Home",
                                              linkPath: "/"
                                            },
                                            {
                                              linkName: "All Data Centers",
                                              linkPath: Routes_Location.index
                                            },
                                            {
                                              linkName: "Countries",
                                              linkPath: Routes_Country.index
                                            },
                                            {
                                              linkName: "States",
                                              linkPath: Routes_State.index
                                            },
                                            {
                                              linkName: "Cities",
                                              linkPath: Routes_City.index
                                            }
                                          ]
                                        }),
                                    className: css.breadcrumbs
                                  }),
                              JsxRuntime.jsx(H1.make, {
                                    className: css.title,
                                    children: "Cities with Data Centers"
                                  }),
                              JsxRuntime.jsx(H2.make, {
                                    className: css.subTitle,
                                    children: state.search === "" ? "There is a total of " + String(state.totalCities) + " " + cities + "\n                in the world with " + String(state.totalLocations) + " " + dataCenters + ", from\n                " + String(state.totalStates) + " " + states + " in\n                " + String(state.totalCountries) + " " + countries + "." : "Your search returned " + String(state.totalCities) + " " + cities + "\n                with " + String(state.totalLocations) + " " + dataCenters + ", from\n                " + String(state.totalStates) + " " + states + " in\n                " + String(state.totalCountries) + " " + countries + "."
                                  })
                            ],
                            className: css.header
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(SearchField.make, {
                                  id: "countries-search",
                                  value: state.search,
                                  placeholder: "Search by city name",
                                  inputClassName: css.searchField,
                                  onChange: (function ($$event) {
                                      dispatch({
                                            TAG: "UpdateSearchInput",
                                            _0: $$event.target.value
                                          });
                                    })
                                }),
                            className: css.subHeader
                          }),
                      JsxRuntime.jsxs(Table.make, {
                            className: css.table,
                            children: [
                              JsxRuntime.jsx(TableHeader.make, {
                                    children: JsxRuntime.jsxs(TableRow.make, {
                                          children: [
                                            JsxRuntime.jsx(TableHeaderCell.make, {
                                                  children: JsxRuntime.jsx(Control.make, {
                                                        onClick: (function (param) {
                                                            dispatch({
                                                                  TAG: "SortBy",
                                                                  _0: "Name"
                                                                });
                                                          }),
                                                        children: JsxRuntime.jsxs("span", {
                                                              children: [
                                                                "City Name",
                                                                JsxRuntime.jsx(IconSorting.make, {
                                                                      size: "XXS",
                                                                      direction: typeof match$1 === "object" && match$1.NAME === "Name" ? match$1.VAL : undefined
                                                                    })
                                                              ],
                                                              className: css.controlCell
                                                            })
                                                      })
                                                }),
                                            JsxRuntime.jsx(TableHeaderCell.make, {
                                                  children: JsxRuntime.jsx(Control.make, {
                                                        onClick: (function (param) {
                                                            dispatch({
                                                                  TAG: "SortBy",
                                                                  _0: "TotalLocations"
                                                                });
                                                          }),
                                                        children: JsxRuntime.jsxs("span", {
                                                              children: [
                                                                "Data Centers",
                                                                JsxRuntime.jsx(IconSorting.make, {
                                                                      size: "XXS",
                                                                      direction: typeof match$2 === "object" && match$2.NAME === "TotalLocations" ? match$2.VAL : undefined
                                                                    })
                                                              ],
                                                              className: css.controlCell
                                                            })
                                                      })
                                                }),
                                            JsxRuntime.jsx(TableHeaderCell.make, {
                                                  displayOnMobile: false,
                                                  children: JsxRuntime.jsx(Control.make, {
                                                        onClick: (function (param) {
                                                            dispatch({
                                                                  TAG: "SortBy",
                                                                  _0: "State"
                                                                });
                                                          }),
                                                        children: JsxRuntime.jsxs("span", {
                                                              children: [
                                                                "State",
                                                                JsxRuntime.jsx(IconSorting.make, {
                                                                      size: "XXS",
                                                                      direction: typeof match$3 === "object" && match$3.NAME === "State" ? match$3.VAL : undefined
                                                                    })
                                                              ],
                                                              className: css.controlCell
                                                            })
                                                      })
                                                }),
                                            JsxRuntime.jsx(TableHeaderCell.make, {
                                                  children: JsxRuntime.jsx(Control.make, {
                                                        onClick: (function (param) {
                                                            dispatch({
                                                                  TAG: "SortBy",
                                                                  _0: "Country"
                                                                });
                                                          }),
                                                        children: JsxRuntime.jsxs("span", {
                                                              children: [
                                                                "Country",
                                                                JsxRuntime.jsx(IconSorting.make, {
                                                                      size: "XXS",
                                                                      direction: typeof match$4 === "object" && match$4.NAME === "Country" ? match$4.VAL : undefined
                                                                    })
                                                              ],
                                                              className: css.controlCell
                                                            })
                                                      })
                                                })
                                          ]
                                        })
                                  }),
                              JsxRuntime.jsx(TableBody.make, {
                                    children: tmp
                                  })
                            ]
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Pagination.make, {
                                  currentPage: state.currentPage,
                                  totalPages: state.totalPages,
                                  onPageClick: (function (x) {
                                      dispatch({
                                            TAG: "UpdatePage",
                                            _0: x
                                          });
                                    })
                                }),
                            className: css.pagination
                          })
                    ]
                  }),
              className: css.pageContainer
            });
}

var $$default = CitiesIndex$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
