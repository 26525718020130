// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as City from "../../../models/City.res.js";
import * as Rest from "../../Rest.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(description, metaTitle, metaDescription, active, altNames) {
  return Json_Encode$JsonCombinators.object([
              [
                "description",
                description
              ],
              [
                "meta_title",
                metaTitle
              ],
              [
                "meta_description",
                metaDescription
              ],
              [
                "active",
                active
              ],
              [
                "alt_names",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(altNames)
              ]
            ]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              city: field.required("city", City.Dashboard.decoder)
            };
    });

var Res = {
  decoder: decoder
};

function exec(id, description, metaTitle, metaDescription, active, altNames) {
  return Rest.$$fetch("/dashboard/cities/" + ID.toString(id), {
              NAME: "Patch",
              VAL: toJson(description, metaTitle, metaDescription, active, altNames)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
